<template>
  <b-list-group>
    <list-item
      v-if="dataItem.id"
      item-label="Form No"
      :item-value="dataItem.id"
    />
    <list-item
      v-if="dataItem.ads"
      item-label="Reklam"
      :item-value="dataItem.ads"
    />
    <list-item
      v-if="dataItem.ads_id"
      item-label="Reklam ID"
      :item-value="dataItem.ads_id"
    />
    <list-item
      v-if="dataItem.brand"
      item-label="Marka"
      :item-value="dataItem.brand"
    />
    <list-item
      v-if="dataItem.model"
      item-label="Model"
      :item-value="dataItem.model"
    />
    <list-item
      v-if="dataItem.campaign"
      item-label="Kampanya"
      :item-value="dataItem.campaign"
    />
    <list-item
      v-if="dataItem.subject"
      item-label="Konu"
      :item-value="dataItem.subject"
    />
    <list-item
      v-if="dataItem.customer"
      item-label="Müşteri Adı"
      :item-value="dataItem.customer"
    />
    <list-item
      v-if="dataItem.company_name"
      item-label="Firma Adı"
      :item-value="dataItem.company_name"
    />
    <list-item
      v-if="dataItem.phone"
      item-label="Telefon"
      :item-value="dataItem.phone"
    />
    <list-item
      v-if="dataItem.email"
      item-label="E-Posta"
      :item-value="dataItem.email"
    />
    <list-item
      v-if="dataItem.meet"
      item-label="Temas"
      :item-value="dataItem.meet"
    />
    <list-item
      v-if="dataItem.username"
      item-label="Danışman"
      :item-value="dataItem.username"
    />
    <list-item
      v-if="dataItem.sdate"
      item-label="Tarih"
      :item-value="moment(dataItem.sdate + ' ' + dataItem.stime).format('LLLL')"
    />
  </b-list-group>
</template>

<script>
import { BListGroup } from 'bootstrap-vue'
import ListItem from '@/views/Webforms/components/ListItem.vue'

export default {
  name: 'TestDriveForm',
  components: {
    BListGroup,
    ListItem,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
  },
}
</script>
